export const responseCodeEnum = {
  success: 0,
  tokenExpire: 102001,
  noGa: 101011,
  cardImportWarn: 101025,
  statusWrong: 101014
}

export const placeEnum = {
  // just for palce
  success: 1
}
